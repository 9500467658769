import http from '../clients/Axios'

export default class LaporanPenjualanService {
  async getLaporanPenjualanPelanggan(start_date, end_date, pelanggan_id) {
    const res = await http.get(
      `/report/penjualan/pelanggan?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}`
    )
    return res
  }

  async exportLaporanPenjualanPelanggan(start_date, end_date, pelanggan_id) {
    return http({
      url: `/report/penjualan/pelanggan/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanSalesman(start_date, end_date, salesman_id) {
    return http({
      url: `/report/penjualan/salesman/barang/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[salesman_id]=${salesman_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanArea(start_date, end_date, area_id) {
    return http({
      url: `/report/penjualan/area/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[area_id]=${area_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanPabrik(start_date, end_date, pabrik_id) {
    return http({
      url: `/report/penjualan/pabrik/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanGolongan(start_date, end_date, golongan_id) {
    return http({
      url: `/report/penjualan/golongan/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[golongan_id]=${golongan_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanBarang(start_date, end_date, obat_id) {
    return http({
      url: `/report/penjualan/barang/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[obat_id]=${obat_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDaftarBarang(pabrik_id) {
    return http({
      url: `/report/penjualan/daftar/print?filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanReturPenjualan(start_date, end_date, type) {
    return http({
      url: `/report/penjualan/retur/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDaftarPenjualan(start_date, end_date, type) {
    return http({
      url: `/report/penjualan/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanDetailPenjualan(start_date, end_date) {
    return http({
      url: `/report/penjualan/detail/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanPelangganBarang(
    start_date,
    end_date,
    pelanggan_id,
    obat_id
  ) {
    return http({
      url: `/report/penjualan/pelanggan/barang/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}&filter[obat_id]=${obat_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanPelangganPabrik(
    start_date,
    end_date,
    pelanggan_id,
    pabrik_id
  ) {
    return http({
      url: `/report/penjualan/pelanggan/pabrik/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pelanggan_id]=${pelanggan_id}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanBarangPabrik(start_date, end_date, pabrik_id) {
    return http({
      url: `/report/penjualan/barang/pabrik/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[pabrik_id]=${pabrik_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanItemBarang(start_date, end_date, obat_id) {
    return http({
      url: `/report/penjualan/barang/item/print?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}&filter[obat_id]=${obat_id}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportLaporanPenjualanSalesmanPabrik(
    start_date,
    end_date,
    salesman_id,
    pabrik_id,
    type
  ) {
    return http({
      url: `/report/penjualan/salesman/pabrik/print?filter[invoice_from]=${start_date}&filter[invoice_to]=${end_date}&filter[salesman_id]=${salesman_id}&filter[pabrik_id]=${pabrik_id}&filter[type]=${type}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
